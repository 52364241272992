<template>
  <div>
    <div class="row">
      <div class="col">
        <h4>Payments</h4>
      </div>
      <div class="col-auto">
        <button
          type="button"
          class="btn btn-type-4 btn-small"
          v-on:click="paymentMethodsModal"
        >
          <span>Payment methods</span>
        </button>
      </div>
    </div>
    <div v-for="country in countries" :key="country.id">
      <b>{{ country.name }}</b>
      <OneByOneTable
        v-if="currencies.length > 0 && payments.length > 0"
        v-model:rows="paymentRows"
        v-model:items="country.paymentMethodPrices"
        type="payment"
        :empty-line="true"
        :show-add="false"
        :key="key"
        v-on:delete="(d) => deleteRow(country.id, d)"
        v-on:update:field="(d, e, f) => update(country.id, d, e, f)"
        v-on:add:field="(e, f) => add(country.id, e, f)"
      />
    </div>
    <PaymentsModal ref="paymentsModal" />
    <ConfirmModal
      ref="deleteModal"
      title="Delete payment price"
      :text="`<ul><li>You will delete a payment price<b></li><li>You can't undo this action</li></ul><p>Are you sure you want to delete?</p>`"
      cancel-button="No, keep the price"
      ok-button="Yes, delete the price"
      v-on:success="deletePrice"
    />
  </div>
</template>

<script>
import http from "@/modules/http";
import OneByOneTable from "@/components/lists/OneByOneTable";
import { useStore } from "vuex";
import ConfirmModal from "@/components/modals/ConfirmModal";
import PaymentsModal from "../../modals/PaymentModal.vue";

export default {
  name: "Payments",
  components: { PaymentsModal, ConfirmModal, OneByOneTable },
  data() {
    return {
      store: useStore(),
      key: 0,
      countries: [],
      payments: [],
      deleteItem: null,
      paymentRows: [
        {
          name: "Name",
          key: "payment_method_id",
          type: "enum",
          label: "name",
          selectKey: "id",
          values: [],
          show: true,
        },
        {
          name: "Currency",
          key: "currency_code",
          type: "enum",
          values: [],
          label: "code",
          selectKey: "code",
          show: true,
        },
        {
          name: "Price",
          key: "price",
          type: "number",
          show: true,
        },
        {
          name: "Min order amount",
          key: "min_order_amount",
          type: "number",
          show: true,
        },
        {
          name: "Min ordered item count",
          key: "min_order_count",
          type: "number",
          show: true,
        },
      ],
    };
  },
  computed: {
    currencies() {
      return this.store.state.currencies;
    },
    warehouses() {
      return this.store.state.warehouses;
    },
  },
  mounted() {
    this.load();
    this.paymentRows[1].values = this.currencies;
  },
  methods: {
    load() {
      http.fetch("/payments/methods").then((data) => {
        this.payments = data;
        this.paymentRows[0].values = data;
        this.key++;
      });
      http.fetch("/countries/details").then((data) => {
        this.countries = data;
      });
    },
    update(country, data, key, val) {
      data.country_id = country;

      if (data.price_id === 0) {
        if (
          data.payment_method_id > 0 &&
          data.currency_code !== undefined &&
          data.price !== undefined
        ) {
          let e = data;
          delete data.price_id;
          http.fetch("/payments/methods", data, true).then((data) => {
            e.price_id = data.id;
          });
        }
      } else {
        let send = {};
        send[key] = val;

        http.fetch("/payments/methods/" + data.price_id, send, true, "PUT");
      }
    },
    add(country, key, val) {
      let row = { price_id: 0 };
      row[key] = val;

      country = this.countries.find((e) => e.id === country);

      if (key !== "currency_code") {
        if (country?.webshop?.currency_code) {
          row["currency_code"] = country.webshop.currency_code;
        } else if (
          this.currencies.findIndex(
            (e) => e.code === country.default_currency
          ) > -1
        ) {
          row["currency_code"] = country.default_currency;
        }
      }

      country.paymentMethodPrices.push(row);
    },
    deleteRow(country, e) {
      this.deleteItem = e;
      this.deleteItem["country_id"] = country;
      this.$refs.deleteModal.showModal();
    },
    paymentMethodsModal() {
      this.$refs.paymentsModal.showModal();
    },
    deletePrice() {
      http
        .fetch(
          "/payments/methods/" + this.deleteItem.price_id,
          {},
          true,
          "DELETE"
        )
        .then(() => {
          let list = this.countries.find(
            (e) => e.id === this.deleteItem["country_id"]
          )?.paymentMethodPrices;
          let e = list.indexOf(this.deleteItem);
          if (e > -1) {
            list.splice(e, 1);
          }
          this.key++;
        });
    },
  },
};
</script>
